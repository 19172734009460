<template>
  <div class="amendments-container" style="max-width:100%;">
    <h1 style="margin-left: 20px;">{{ $t("evaluations") }}</h1>
    <div style="display:flex">
      <div class="support-con2">
        <div class="con1">
          <h3 @click="showOrganizationsTable = true">
            {{ $t("list-of-participants") }}
          </h3>
          <h3>{{ $t("list-of-id-cards") }}</h3>
          <h3>{{ $t("list-of-organizations") }}</h3>
          <h3>{{ $t("list-of-users") }}</h3>
        </div>
        <div
          v-if="showOrganizationsTable"
          style="display: flex; flex-direction: column;"
        >
          <div class="mt-2" style="text-align: left;margin-left: 30px;">
            <h6 class="p-2" style="color:grey; font-size: 0.9rem;">
              {{ $t("select-persons-for-pdf") }}
            </h6>
          </div>
          <div style="display: flex;">
            <div>
              <div class="mt-4" style="margin-right: 100px; margin-left: 40px;">
                <vs-input
                  class="search-input org-width"
                  icon-after="search"
                  :placeholder="$t('organization-search')"
                  v-model="oranization_search"
                  icon="search"
                  icon-no-border
                  label-placeholder="icon-no-border"
                />
                <div class="outer-con3">
                  <div class="table-container2">
                    <table>
                      <thead>
                        <tr>
                          <th @click="sort('name')">
                            {{ $t("organization") }}
                            <div
                              class="arrow"
                              v-if="'name' == currentSort"
                              v-bind:class="
                                ascending ? 'arrow_up' : 'arrow_down'
                              "
                            ></div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          class="cursor"
                          v-for="organization in organizations"
                          :key="organization.id"
                          @click="selectOrganization(organization)"
                        >
                          <td
                            :class="{
                              active: isActiveOrganization(organization.id),
                            }"
                          >
                            {{ organization.name }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="
                selectedOrganization != null &&
                  idCardsByOrganization.length == 0 &&
                  selectedOrganization
              "
              class="table-container2"
            >
              <vs-input
                class="search-input2"
                style="margin-top: 23px;"
                icon-after="search"
                :placeholder="$t('name-search')"
                v-model="person_search"
                icon="search"
                icon-no-border
                label-placeholder="icon-no-border"
              />
              <table v-if="selectedOrganization != null">
                <thead></thead>
                <tbody>
                  <td>Keine Ausweise vorhanden</td>
                </tbody>
              </table>
            </div>
            <div
              v-if="
                selectedOrganization != null &&
                  idCardsByOrganization.length > 0 &&
                  selectedOrganization
              "
              class="table-container2"
              style="padding-left: 100px; margin-top: 6px; border-left:1px solid #CCCCCC;"
            >
              <vs-input
                class="search-input2"
                style="padding-bottom: 2px;"
                icon-after="search"
                :placeholder="$t('name-search')"
                v-model="person_search"
                icon="search"
                icon-no-border
                label-placeholder="icon-no-border"
              />
              <div class="outer-con3">
                <table v-if="selectedOrganization != null">
                  <thead>
                    <tr>
                      <th>
                        <input
                          style="margin-left: 5px;"
                          type="checkbox"
                          v-model="selectAll"
                          @change="selectAllItems"
                        />
                      </th>
                      <th @click="sortA('person_last_name')">
                        {{ $t("Name") }}
                        <div
                          class="arrow"
                          v-if="'person_last_name' == currentSortA"
                          v-bind:class="ascendingA ? 'arrow_up' : 'arrow_down'"
                        ></div>
                      </th>
                      <th @click="sortA('person_first_name')">
                        {{ $t("Vorname") }}
                        <div
                          class="arrow"
                          v-if="'person_first_name' == currentSortA"
                          v-bind:class="ascendingA ? 'arrow_up' : 'arrow_down'"
                        ></div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="cursor"
                      v-for="idCard in idCardsByOrganization"
                      :key="idCard.id"
                    >
                      <td>
                        <input
                          type="checkbox"
                          :checked="isInSelectedIdCards(idCard)"
                          v-model="idCard.selected"
                          @click="addIdCard(idCard)"
                        />
                      </td>
                      <td>{{ idCard.person_last_name }}</td>
                      <td>{{ idCard.person_first_name }}</td>
                    </tr>
                  </tbody>
                </table>
                <div v-else>
                  Keine Organisation ausgewählt
                </div>
              </div>
              <div
      style="display: flex; justify-content: flex-end; margin-top: 5px;"
    >
      <div
        v-if="selectedOrganization && (selectedPersons.length > 0 || selectAll)"
        style="margin-left: 5px;margin-top: 40px;"
      >
      <div style="position: relative;">
      <label
        for="selectedDate"
        style="position: absolute; top: -17px; left: 3px; font-size: 12px; color: #999999;"
      >
        {{ $t('valid-until') + '*' }}
      </label>
      <vs-input
        id="selectedDate"
        type="date"
        class="inputx spec-input"
        v-model="selectedDate"
      />
      </div>
      </div>
      <div
        v-if="selectedOrganization && (selectedPersons.length > 0 || selectAll)"
        style="margin-left: 50px; margin-top: 40px;"
      >
        <vs-button
          @click="generatePDF()"
          class="generate-pdf-button"
          icon="download"
        >
          {{ $t("generate-pdf") }}
        </vs-button>
      </div>
    </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import PopupIDcard from "../components/id-card/PopupIDcard.vue";
import PopupImportData from "../components/id-card/PopupImportData.vue";
import axios from "axios";

export default Vue.extend({
  name: "Evaluations",
  components: {
    PopupIDcard,
    PopupImportData,
  },
  async mounted() {
    await this.$store.dispatch("organization/loadOrganizations");
    // await this.$store.dispatch('idCard/loadIdCards')
  },
  computed: {
    idCardsByOrganization: function() {
      if (this.selectedOrganization) {
        let idCards = this.$store.getters["idCard/getIdCards"];

        idCards = idCards.filter(
          (o) => o.organisation_id === this.selectedOrganization.id
        );

        if (this.person_search !== "") {
          return idCards.filter((o) => {
            return (
              o.person_first_name
                .toLowerCase()
                .includes(this.person_search.toLowerCase()) ||
              o.person_last_name
                .toLowerCase()
                .includes(this.person_search.toLowerCase())
            );
          });
        }
        idCards = idCards.filter((o) => o.status === 'active');

        return idCards.sort((a, b) => {
          let modifier = 1;
          if (this.currentSortDirA === "desc") modifier = -1;
          if (a[this.currentSortA] < b[this.currentSortA]) return -1 * modifier;
          if (a[this.currentSortA] > b[this.currentSortA]) return 1 * modifier;
          return 0;
        });
      } else {
        return null;
      }
    },

    organizations: function() {
      let org = this.$store.getters["organization/getOrganizations"];
      const user = this.$store.getters["auth/getUser"];
      if (!user.is_customer_admin) {
        if (user.organisation_mappings) {
          org = org.filter((u) => {
            return user.organisation_mappings.find((o) => {
              if (o.organisation_id === u.id) {
                if (o.id_card_permission) {
                  return true;
                }
              }
            });
          });
        } else {
          return null;
        }
      }

      if (this.oranization_search !== "") {
        return org.filter((o) =>
          o.name.toLowerCase().includes(this.oranization_search.toLowerCase())
        );
      }
      return org.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },

    organization() {
      return this.$store.getters["organization/getOrganization"];
    },
  },
  data() {
    return {
      selectedOrganization: {},
      oranization_search: "",
      person_search: "",
      ascending: false,
      currentSort: "name",
      ascendingA: false,
      currentSortDir: "asc",
      currentSortA: "person_last_name",
      currentSortDirA: "asc",
      selectAll: false,
      showOrganizationsTable: false,
      selectedOrganization: null,
      selectedPersons: [],
      selectedDate: this.getDefaultDate(),
    };
  },

  watch: {
    selectedOrganization() {
      // Setze selectAll wieder auf false, wenn die Organisation gewechselt wird
      this.selectAll = false;
    },
  },

  methods: {
    getDefaultDate() {
      const today = new Date();
      const defaultDate = new Date(today.setDate(today.getDate() + 7));
      return defaultDate.toISOString().slice(0, 10);
    },
    validateDate(date) {
      const selectedDate = new Date(date);
      const today = new Date();
      const twoWeeksLater = new Date(today.setDate(today.getDate() + 14));
      return selectedDate <= twoWeeksLater;
    },

    addIdCard(idCard) {
      if (this.isInSelectedIdCards(idCard)) {
        this.selectedPersons = this.selectedPersons.filter((e) => {
          return e.id != idCard.id;
        });
      } else {
        this.selectedPersons.push(idCard);
      }
    },

    isInSelectedIdCards(idCard) {
      return this.selectedPersons.find((e) => {
        return e.id == idCard.id;
      });
    },

    // selectOrganization(organization) {
    //   console.log("ich bin hier")
    //   this.selectedPersons = [];
    //   this.selectedOrganization = organization;
    // },
    async generatePDF() {
      // Überprüfen, ob Personen ausgewählt wurden
      if (this.selectedPersons.length === 0) {
        this.$vs.notify({
          color: "danger",
          position: "bottom-right",
          title: "Es wurden keine Personen ausgewählt.",
        });
        return;
      }
      if (!this.selectedDate) {
        this.$vs.notify({
          color: "danger",
          position: "bottom-right",
          title: "Es wurde kein Datum ausgewählt.",
        });
        return;
      }
      if (!this.validateDate(this.selectedDate)) {
        this.$vs.notify({
          color: "danger",
          position: "bottom-right",
          title:
            "Das ausgewählte Datum darf nicht mehr als zwei Wochen in der Zukunft liegen.",
        });
        return;
      }
      var tempData = {
        id: this.selectedOrganization.id,
        list: this.selectedPersons.map((person) => person.person_id),
        dt: this.selectedDate,
      };
      try {
        await this.$store.dispatch(
          "organization/loadParticipantList",
          tempData
        );
      } catch (error) {
        console.error("Fehler beim Laden der Teilnehmerliste:", error);
      }
    },

    async selectOrganization(organization) {
      this.selectedPersons = [];
      await this.$store.dispatch(
        "organization/loadOrganization",
        organization.id
      );
      await this.$store.dispatch("idCard/loadIdCards", {
        organisation_id: organization.id,
      });
      this.selectedOrganization = this.organization;
    },
    isActiveOrganization(organizationId) {
      if (this.selectedOrganization) {
        return organizationId === this.selectedOrganization.id;
      }
      return false;
    },
    selectAllItems() {
      // Alle Checkboxen in der Tabelle basierend auf selectAll setzen
      this.idCardsByOrganization.forEach((idCard) => {
        idCard.selected = this.selectAll;
        if (!idCard.selected && this.isInSelectedIdCards(idCard)) {
          this.selectedPersons = this.selectedPersons.filter((e) => {
            return e.id != idCard.id;
          });
        } else {
          if (idCard.selected) {
            this.selectedPersons.push(idCard);
          }
        }
      });
    },
    sortA: function(s) {
      if (s === this.currentSortA) {
        this.currentSortDirA = this.currentSortDirA === "asc" ? "desc" : "asc";
        this.ascendingA = !this.ascendingA;
      }
      this.currentSortA = s;
    },
    sort: function(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
        this.ascending = !this.ascending;
      }
      this.currentSort = s;
    },
  },
});
</script>
<style scoped>
.generate-pdf-button {
  position: relative;
  float: left;
  background-color: #007bff;
  color: #ffffff;
}
</style>
